/* eslint-disable */

<template>
  <div>
    <div class="vx-row">
      <div class="vx-col lg:w-5/12 sm:w-5/5">
        <vx-card class="mb-base">
          <div>
            <div class="flex bg-light rounded-lg main-border p-4">
              <feather-icon icon="DownloadIcon" svgClasses="mr-4 self-center" />
              <h3 class="font-bold self-center">Download Center</h3>
            </div>
            <vs-breadcrumb
              class="mt-1 white"
              :items="[{ title: 'Home' }, { title: 'Download Center' }, { title: 'Application Patch', active: true }]"
              separator="chevron_right"
            ></vs-breadcrumb>
            <div class="p-1 pt-1">
              <h5>
                The links in this section correspond to separate files available in this download center. Download the most appropriate file
              </h5>
              <vs-table :data="downloads" class="mt-3 main-border">
                <template slot="header">
                  <h3 class="p-4 main-bottom-border w-full">Download Mirrors</h3>
                </template>

                <template slot="thead">
                  <vs-th> File Name </vs-th>
                  <vs-th> Mirror </vs-th>
                  <vs-th> Download</vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>{{ data[indextr].name }}</vs-td>
                    <vs-td>{{ data[indextr].mirror }}</vs-td>
                    <vs-td>
                      <vx-tooltip :text="data[indextr].url">
                        <vs-button
                          @click="download_file(data[indextr].id)"
                          class="round self-center"
                          icon="icon-download"
                          icon-pack="feather"
                          color="primary"
                          type="gradient"
                          >Download
                        </vs-button>
                      </vx-tooltip>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>
        </vx-card>
      </div>
      <div class="vx-col lg:w-7/12 sm:w-5/5">
        <vue-command
          id="command_prompt"
          :commands="commands"
          :prompt="'C:\\Users\\' + this.activeUserInfo.firstName.toLowerCase().replace(/ /g, '') + '\\Downloads>'"
          class="rounded-lg main-border"
          intro="Microsoft Windows [Version 10.0.18363.1256] (c) 2019 Microsoft Corporation. All rights reserved."
          :show-intro="true"
          style="background-color: #111111"
          title="Command Prompt"
        />

        <div class="flex hash-container p-5 mt-6">
          <feather-icon icon="InfoIcon" svgClasses="mr-4 self-center" />
          <h3 class="font-bold self-center">HASH: 1759adb5g34700aae19bc4578fc19cc2</h3>
        </div>
      </div>
    </div>

    <movable-popup
      refVal="movableDownloadRef"
      title="Downloading File - Click Here to Drag This Popup"
      :active="currently_downloading_file"
      :close-button="false"
    >
      <template v-slot:content>
        <div class="flex justify-center mt-5 mb-6">
          <img
            src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/casp-plus/240444036513529856/DC2.png"
            width="150"
            alt="Check"
          />
        </div>
        <h1 class="text-center font-bold my-6" style="font-size: 38px">Downloading File | Mirror {{ currently_downloading_mirror }}</h1>
        <h3 class="text-center font-light mb-3">Please Wait, This Should Only Take A Couple Seconds</h3>
        <div class="p-5">
          <vs-progress indeterminate color="primary"></vs-progress>
        </div>
      </template>
    </movable-popup>

    <movable-popup
      refVal="movableInstallRef"
      title="Installing App - Click Here to Drag This Popup"
      :active="currently_installing_app"
      :close-button="false"
    >
      <template v-slot:content>
        <div class="flex justify-center mt-5 mb-6">
          <img
            src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/casp-plus/240444036513529856/Install.png"
            width="150"
            alt="Check"
          />
        </div>
        <h1 class="text-center font-bold my-6" style="font-size: 38px">Installing Application</h1>
        <h3 class="text-center font-light mb-3">Please Wait, This Should Only Take A Couple Seconds</h3>
        <div class="p-5">
          <vs-progress indeterminate color="primary"></vs-progress>
        </div>
      </template>
    </movable-popup>

    <movable-popup
      refVal="movableStep3Ref"
      title="Security Alert - Click Here to Drag This Popup"
      :active="step_3_download.popup"
      :close-button="false"
    >
      <template v-slot:content>
        <div>
          <div class="font-bold">
            <h4>
              Information you exchange with this site cannot be viewed or changed by others. However, there is a problem with the site's
              security certificate.
            </h4>
          </div>
          <div class="flex my-6">
            <feather-icon icon="AlertCircleIcon" svgClasses="w-8 h-8 mr-2 text-warning"></feather-icon>
            <h5 class="font-light ml-2">
              The security certificate was issued by a company you have not chosen to trust. View the certificate to determine whether you
              want to trust the certifying authority.
            </h5>
          </div>
          <div class="flex my-6">
            <feather-icon icon="CheckCircleIcon" svgClasses="w-8 h-8 mr-2 text-success"></feather-icon>
            <h5 class="font-light ml-2">The security certificate date is valid.</h5>
          </div>
          <div class="flex my-6">
            <feather-icon icon="AlertCircleIcon" svgClasses="w-8 h-8 mr-2 text-warning"></feather-icon>
            <h5 class="font-light ml-2">The name on the security certificate does not match the name of the site.</h5>
          </div>
          <div class="font-bold mt-8"><h5>Do you want to proceed?</h5></div>
          <vs-divider />
          <div class="flex items-end justify-end">
            <vs-button color="danger" class="mr-2" @click="step_3_download.popup = false">No</vs-button>
            <vs-button
              color="success"
              class="ml-2"
              @click="
                step_3_download.accepted = true;
                step_3_download.popup = false;
                download_file(3);
              "
              >Yes</vs-button
            >
          </div>
        </div>
      </template>
    </movable-popup>

    <movable-popup refVal="movableStep4Ref" title="Open File - Security Warning" :active="install_app_uac" :close-button="false">
      <template v-slot:content>
        <div class="font-bold"><h4>Do you want to run this file?</h4></div>
        <div class="my-4 ml-6">
          <h5 class="font-light ml-2">Name: install.exe</h5>
        </div>
        <div class="my-4 ml-6">
          <h5 class="font-light ml-2">Publisher: <span class="underline text-primary">Software Limited</span></h5>
        </div>
        <div class="my-4 ml-6">
          <h5 class="font-light ml-2">Type: Application</h5>
        </div>
        <div class="my-4 ml-6">
          <h5 class="font-light ml-2">From: C:\Downloads</h5>
        </div>

        <div class="flex items-end justify-end">
          <vs-button color="danger" class="mr-2" @click="install_app_uac = false">No</vs-button>
          <vs-button color="success" class="ml-2" @click="install_app">Yes </vs-button>
        </div>
        <vs-divider />
        <div class="flex">
          <feather-icon icon="AlertCircleIcon" svgClasses="w-12 h-12 mr-2 text-warning"></feather-icon>
          <h5 class="font-light ml-2 self-center">
            While files from the Internet can be useful, this file type can potentially harm your computer. Only run software from
            publishers you trust.
          </h5>
        </div>
      </template>
    </movable-popup>

    <vs-popup :active.sync="simulation_download_wrong_popup.active" class="pb-3" :title="simulation_download_wrong_popup.title">
      <div class="flex justify-center mt-3 mb-5">
        <img src="https://connect-cdn.intellectualpoint.com/assets/images/icons/simulation-popup/danger.png" width="150" alt="Check" />
      </div>
      <h1 class="text-center font-bold my-6" style="font-size: 42px" v-html="simulation_download_wrong_popup.title" />
      <h3 class="text-center font-light mb-3" v-html="simulation_download_wrong_popup.desc" />
      <div class="flex justify-center mt-3">
        <vs-button class="mt-5 mb-5" color="warning" @click="simulation_download_wrong_popup.active = false">Try Again </vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import VueCommand, { createStdout, createStderr } from 'vue-command';
import 'vue-command/dist/vue-command.css';
import MovablePopup from '../../../../../../../../components/popup/MovablePopup';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      downloads: [
        {
          id: 1,
          name: 'install.exe',
          mirror: 'Mirror 1',
          url: 'https://download-test.com/files/install.exe',
        },
        {
          id: 2,
          name: 'install.exe',
          mirror: 'Mirror 2',
          url: 'https://download-test.com/files/install.exe',
        },
        {
          id: 3,
          name: 'install.exe',
          mirror: 'Mirror 3',
          url: 'https://download-test.com/files/install.exe',
        },
        {
          id: 4,
          name: 'install.exe',
          mirror: 'Mirror 4',
          url: 'https://download-test.com/files/install.exe',
        },
        {
          id: 5,
          name: 'install.exe',
          mirror: 'Mirror 5',
          url: 'http://download-test.com/files/install.exe',
        },
        {
          id: 6,
          name: 'install.exe',
          mirror: 'Mirror 6',
          url: 'http://download-test.com/files/install.exe',
        },
      ],

      currently_downloaded: null,
      currently_downloading_file: false,
      currently_downloading_mirror: null,
      currently_downloading_sound: null,

      install_app_uac: false,
      currently_installing_app: false,

      hash_verified: false,
      install_simulation_popup: false,

      uac_sound: null,
      step_3_download: {
        popup: false,
        accepted: false,
      },

      simulation_download_wrong_popup: {
        active: false,
        title: '',
        desc: '',
      },

      commands: {
        mema: () => createStdout('Is the best developer in the world'),
        help: () =>
          createStdout(
            'Available commands:<br>&nbsp;help: Show available commands<br>&nbsp;md5sum [file]: calculate/verify md5 hashes<br>&nbsp;dir: index the current directory',
          ),
        md5sum: () => undefined,
        dir: () => undefined,
        'install.exe': () => undefined,
      },
    };
  },
  methods: {
    download_file(mirror) {
      if (this.currently_downloading_file !== false) {
        return false;
      }
      if (mirror === 3 && this.step_3_download.accepted === false) {
        this.uac_sound.play();
        this.currently_downloaded = null;
        this.step_3_download.popup = true;
        return false;
      }
      this.currently_downloading_sound.play();
      this.currently_downloading_file = true;
      this.currently_downloading_mirror = mirror;
      this.hash_verified = false;
      setTimeout(() => {
        this.currently_downloading_file = 'transition_out';
        setTimeout(() => {
          this.currently_downloading_file = false;
        }, 400);
        if (mirror === 5 || mirror === 6) {
          window.simulation_getting_there_sound.play();
          this.currently_downloaded = null;
          this.simulation_download_wrong_popup = {
            active: true,
            title: 'Simulation Failed',
            desc: 'You downloaded a file using the HTTP protocol, try again.',
          };
          return false;
        }
        this.currently_downloaded = mirror;
        return this.$vs.notify({
          title: 'File Downloaded Successfully',
          text: `Your File From Mirror #${mirror} Has Been Downloaded & Saved Into C:\\Downloads`,
          color: 'success',
          iconPack: 'feather',
          position: 'bottom-right',
          icon: 'icon-check-circle',
        });
      }, 4500);
    },
    make_id(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    install_app() {
      this.install_app_uac = false;
      setTimeout(() => {
        this.currently_installing_app = true;
      }, 100);
      setTimeout(() => {
        this.$emit('simulation_full_correct', 1);
        this.currently_installing_app = false;
      }, 5000);
    },
  },
  mounted() {
    // Disable Mark Button and Update Options in Renderer
    this.dataLocal.options.showMarkButton = false;
    this.$emit('simulation_update_options', this.dataLocal);

    // Mount Needed Sounds
    this.currently_downloading_sound = new Audio('https://sims-cdn.intellectualpoint.com/sounds/effect/SoundDL.mp3');
    this.uac_sound = new Audio('https://sims-cdn.intellectualpoint.com/sounds/effect/Windows_11_UAC_Sound.mp3');

    this.commands.dir = () => {
      if (this.currently_downloaded === null) {
        return createStdout(
          'Volume in drive C has no label.<br>\n' +
            '                Volume Serial Number is 945F-DBDF<br>\n' +
            '                Directory of C:\\Downloads<br>\n' +
            '                11/03/2010 ‎ ‎ ‎ ‎‎12:54 PM ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ <‎ ‎DIR ‎> ‎ .<br>\n' +
            '                11/03/2010 ‎ ‎ ‎ ‎‎12:54 PM ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎<‎ ‎DIR ‎> ‎ ..<br>\n' +
            '                08/23/2001 ‎ ‎ ‎ 07:00 AM ‎ ‎ ‎  ‎ ‎ ‎  ‎ ‎ ‎  ‎ ‎ 11,264 attrib.exe<br>\n' +
            '                08/23/2001 ‎ ‎ ‎ 07:00 AM ‎ ‎ ‎  ‎ ‎ ‎  ‎ ‎ ‎  ‎ ‎ 18,432 cacls.exe<br>\n' +
            '                04/20/2005 ‎ ‎ ‎ 08:41 PM ‎ ‎ ‎  ‎ ‎ ‎  ‎ ‎ ‎  ‎ ‎ 41,984 md5sum.exe<br>\n' +
            '                04/20/2005 ‎ ‎ ‎ 08:41 PM ‎ ‎ ‎  ‎ ‎ ‎  ‎ ‎ ‎  ‎ ‎ 41,984 shalsum.exe<br>\n' +
            '                 ‎ ‎ ‎  ‎ ‎ ‎ ‎ ‎ ‎ ‎  ‎ ‎  ‎ ‎ ‎4 File(s) ‎ ‎ ‎‎  ‎ ‎ ‎ ‎ ‎ ‎  ‎ ‎113,664 bytes<br>\n' +
            '                 ‎ ‎ ‎  ‎ ‎ ‎ ‎ ‎ ‎ ‎  ‎ ‎  ‎ ‎ ‎2 Dir(s) ‎ ‎ ‎  ‎‎ ‎ ‎ ‎ ‎ ‎ ‎‎  ‎ 44,934,369,280 bytes free',
        );
      }

      return createStdout(
        'Volume in drive C has no label.<br>\n' +
          '                Volume Serial Number is 945F-DBDF<br>\n' +
          '                Directory of C:\\Downloads<br>\n' +
          '                11/03/2010 ‎ ‎ ‎ ‎‎12:54 PM ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ <‎ ‎DIR ‎> ‎ .<br>\n' +
          '                11/03/2010 ‎ ‎ ‎ ‎‎12:54 PM ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎<‎ ‎DIR ‎> ‎ ..<br>\n' +
          '                08/23/2001 ‎ ‎ ‎ 07:00 AM ‎ ‎ ‎  ‎ ‎ ‎  ‎ ‎ ‎  ‎ ‎ 11,264 attrib.exe<br>\n' +
          '                08/23/2001 ‎ ‎ ‎ 07:00 AM ‎ ‎ ‎  ‎ ‎ ‎  ‎ ‎ ‎  ‎ ‎ 18,432 cacls.exe<br>\n' +
          '                11/03/2010 ‎ ‎ ‎ ‎‎12:53 PM ‎ ‎ ‎  ‎ ‎ ‎  ‎ ‎ ‎  ‎ ‎ 5,176,996 install.exe<br>\n' +
          '                04/20/2005 ‎ ‎ ‎ 08:41 PM ‎ ‎ ‎  ‎ ‎ ‎  ‎ ‎ ‎  ‎ ‎ 41,984 md5sum.exe<br>\n' +
          '                04/20/2005 ‎ ‎ ‎ 08:41 PM ‎ ‎ ‎  ‎ ‎ ‎  ‎ ‎ ‎  ‎ ‎ 41,984 shalsum.exe<br>\n' +
          '                 ‎ ‎ ‎  ‎ ‎ ‎ ‎ ‎ ‎ ‎  ‎ ‎  ‎ ‎ ‎4 File(s) ‎ ‎ ‎‎  ‎ ‎ ‎ ‎ ‎ ‎  ‎ ‎113,664 bytes<br>\n' +
          '                 ‎ ‎ ‎  ‎ ‎ ‎ ‎ ‎ ‎ ‎  ‎ ‎  ‎ ‎ ‎2 Dir(s) ‎ ‎ ‎  ‎‎ ‎ ‎ ‎ ‎ ‎ ‎‎  ‎ 44,934,369,280 bytes free',
      );
    };
    this.commands.md5sum = (x) => {
      if (x._[1] === undefined) {
        return createStderr('<span class="text-danger">Invalid Usage: md5sum [file]</span>');
      }

      const other_files = ['attrib.exe', 'cacls.exe', 'md5sum.exe', 'shalsum.exe'];

      if (other_files.includes(x._[1])) {
        const random = this.make_id(32);
        return createStdout(`<span class="text-success">${random} *${x._[1]}</span>`);
      }

      if (x._[1] !== 'install.exe') {
        return createStderr(`<span class="text-danger">File ${x._[1]} Does Not Exist</span>`);
      }

      if (x._[1] === 'install.exe') {
        if (this.currently_downloaded === null) {
          return createStderr('<span class="text-danger">File install.exe Does Not Exist</span>');
        }

        this.hash_verified = true;

        if (this.currently_downloaded !== 4) {
          const random = this.make_id(32);
          return createStdout(`<span class="text-success">${random} *install.exe</span>`);
        }

        if (this.currently_downloaded === 4) {
          return createStdout('<span class="text-success">1759adb5g34700aae19bc4578fc19cc2 *install.exe</span>');
        }
      }
      return createStderr('Invalid Usage: md5sum [file]');
    };

    this.commands['install.exe'] = () => {
      if (this.currently_downloaded === null) {
        return createStderr('<span class="text-danger">File install.exe Does Not Exist</span>');
      }

      if (this.hash_verified === false) {
        return createStderr('<span class="text-danger">Please Verify The Hash Before Installing</span>');
      }

      if (this.currently_downloaded !== 4) {
        window.simulation_getting_there_sound.play();
        this.simulation_download_wrong_popup = {
          active: true,
          title: 'Simulation Failed',
          desc: 'The hash of the file you downloaded does not match the hash in the instructions.',
        };
        return createStderr('<span class="text-danger">Simulation Failed, Try Again</span>');
      }

      if (this.currently_downloaded === 4) {
        this.install_app_uac = true;
        this.uac_sound.play();
        return createStdout('<span class="text-success">Installing...</span>');
      }

      return createStderr('Invalid Usage: md5sum [file]');
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    Prism,
    VueCommand,
    MovablePopup,
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
};
</script>
<style scoped>
.hash-container {
  border: 1px solid #ff9f43;
  background-color: rgb(255, 158, 66, 0.3);
  border-radius: 0.5rem;
}
</style>
